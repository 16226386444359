<template>
  <b-card>
      <!-- form -->
      <b-form class="mt-2">

        <b-row class="mt-2">
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Description") }}</label>
            <b-form-textarea
              id="description"
              v-model="description"
              class="source-input"
              placeholder="Tall textarea"
              rows="8"
              no-auto-shrink
            />
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Supplementary Info") }}</label>
            <b-form-textarea
              id="supplementaryInfo"
              v-model="supplementaryInfo"
              class="source-input"
              placeholder="Tall textarea"
              rows="8"
              no-auto-shrink
            />
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Reference Samples") }}</label>
            <b-form-textarea
              id="referenceSamples"
              v-model="referenceSamples"
              class="source-input"
              placeholder="Tall textarea"
              rows="8"
              no-auto-shrink
            />
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <label class="mb-1">{{ $t("Research Links") }}</label>
            <b-form-textarea
              id="researchLinks"
              v-model="researchLinks"
              class="source-input"
              placeholder="Tall textarea"
              rows="8"
              no-auto-shrink
            />
          </b-col>
        </b-row>
        
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
  </b-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import Ripple from 'vue-ripple-directive'
import { getDataVersion } from '@/store/ancestry-data/utils'

import SaveButton from '@/components/SaveButton.vue';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
// Alert
import AlertService from '@/common/alert.service'

const { mapFields } = createHelpers({
  getterType: 'presetMapManagement/getMapField',
  mutationType: 'presetMapManagement/updateMapField',
});

export default {
  components: {
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  methods: {
    saveItem(isClose) {
      const query = this.$store.getters['presetMapManagement/getMapForm'];
      if (query.dataVersion !== getDataVersion(this.$store)) {
        AlertService.warning(this, 'Data version does not match selected version');
        return;
      }

      this.$store.dispatch('presetMapManagement/savePresetMap', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })

            if (isClose) {
              this.$router.go(-1)
            } else {
              this.$store.commit('presetMapManagement/setMapField', response.result);
              this.$router.push({ name: 'manage-preset-map-save', params: { id: response.result.id } });
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  computed: {
    ...mapFields([
      'description',
      'supplementaryInfo',
      'referenceSamples',
      'researchLinks',
    ]),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.source-input {
    box-sizing: border-box;
    margin: 0px;
    outline: 0px;
    padding: 1em;
    height: calc(100% - 3em);
    min-height: 3em;
    width: 100%;
    overflow: auto;
    color: inherit;
    font-size: 0.7em;
    white-space: pre;
}
</style>
